import React from "react";
import * as S from "./Section.styles";

interface Props {
  children: React.ReactNode;
  bgColor: string;
  id: string;
}

const Section: React.FC<Props> = ({ children, bgColor, id }) => {
  return (
    <S.Section id={id} color={bgColor}>
      {children}
    </S.Section>
  );
};

export default Section;

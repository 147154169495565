import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ContentQuery } from "../../../graphql-types";
import { Link } from "gatsby";
import slugify from "@sindresorhus/slugify";
import moment from "moment";
import * as S from "./Card.styles";
import { ScrollContext } from "../../contexts/ScrollContext";

interface Props {
  data: ContentQuery["allContentfulWebsiteContent"]["nodes"][0];
}

const Card = ({ data }: Props) => {
  const image = getImage(data.image?.gatsbyImageData);
  const url = data?.title && slugify(data.title);
  const { setScrollY } = React.useContext(ScrollContext);

  return (
    <S.Card onClick={() => setScrollY(window.scrollY)} id={url ?? ""}>
      <Link to={`/${url}`}>
        {image ? <GatsbyImage image={image} alt={data.title ?? "alt"} /> : null}
        <S.Details>
          <S.Title>{data.title}</S.Title>
          <S.Artist>{data.artist}</S.Artist>
          <S.Date>{moment(data.date).format("Do MMMM YYYY")}</S.Date>
        </S.Details>
      </Link>
    </S.Card>
  );
};

export default Card;

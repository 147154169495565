import React from "react";
import * as S from "./Subscribe.styles";
import { FormContext } from "../../contexts/FormContext";

const Subscribe = () => {
  const { open, isOpen } = React.useContext(FormContext);
  const [mobile, isMobile] = React.useState<boolean>();
  const [opacity, setOpacity] = React.useState<number>(0);
  const subscribed = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (window.innerWidth < 760) {
      isMobile(true);
    } else {
      isMobile(false);

      const hasSubscribed = sessionStorage.getItem("subscribed");
      if (hasSubscribed === "true") {
        subscribed.current = true;
      }
    }

    const closedByUser = sessionStorage.getItem("closedByUser");
    if (closedByUser !== "true") {
      isOpen(true);
      setTimeout(() => {
        setOpacity(1);
      }, 50);
    }
  }, []);

  const submitForm = () => {
    sessionStorage.setItem("subscribed", "true");
  };

  const closeForm = () => {
    setOpacity(0);
    setTimeout(() => {
      isOpen(false);
    }, 600);

    sessionStorage.setItem("closedByUser", "true");
  };

  return subscribed.current ? (
    <S.Subscribe display={open ? "true" : "none"} opacity={opacity}>
      <S.Message>Thank you for subscribing!</S.Message>
      <S.CloseButton onClick={closeForm}>X</S.CloseButton>
    </S.Subscribe>
  ) : (
    <S.Subscribe display={open ? "true" : "none"} opacity={opacity}>
      {mobile ? (
        <S.Link target="_blank" href="https://eepurl.com/hpTu1D">
          Subscribe to the Ormside mailing list
        </S.Link>
      ) : (
        <S.Form
          action="https://ormiside.us7.list-manage.com/subscribe/post"
          method="POST"
          onSubmit={submitForm}
        >
          <S.Input type="hidden" name="u" value="8076ef518d4899e2a55b83370" />
          <S.Input type="hidden" name="id" value="f6c70865fe" />
          <S.Label>Subscribe to the Ormside mailing list</S.Label>
          <S.Input
            type="email"
            name="MERGE0"
            id=""
            placeholder="email address"
          />
          <S.SubButton type="submit">Subscribe</S.SubButton>
        </S.Form>
      )}
      <S.CloseButton onClick={closeForm}>X</S.CloseButton>
    </S.Subscribe>
  );
};

export default Subscribe;

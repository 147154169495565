import styled from "styled-components";

export const CloseButton = styled.button`
  border: none;
  color: ${({ theme }) => theme.colors.secondary};
  margin-right: 10px;
  padding: 3px;
  position: absolute;
  right: 0;

  @media only screen and (max-width: 345px) {
    margin-right: 2px;
    top: 0;
  }
`;

export const Form = styled.form`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: 446px) {
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 5px;
    }
  }
`;

export const Input = styled.input`
  background: ${({ theme }) => theme.colors.primary};
  border: 1px solid;
  border-width: 0 0 1px;
  border-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.secondary};
  outline: 0;
  padding: 4px;
  width: 20%;

  @media only screen and (min-width: 447px) and (max-width: 768px) {
    width: 35%;
  }

  @media only screen and (max-width: 446px) {
    border: none;
    text-align: center;
    width: 80%;
  }
`;

export const Label = styled.label`
  margin-right: 1rem;
`;

export const Link = styled.a`
  font-weight: lighter;
  text-decoration: underline;
`;

export const Message = styled.p``;

export const Subscribe = styled.div<{ display: string; opacity: number }>`
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  bottom: 0;
  color: ${({ theme }) => theme.colors.secondary};
  display: ${(props) => (props.display === "true" ? "flex" : "none")};
  font-size: 1rem;
  justify-content: center;
  padding: 10px;
  position: fixed;
  width: 100vw;

  opacity: ${(props) => props.opacity};
  transition: opacity 0.5s ease-in-out;

  * > * {
    font-size: 1rem;
  }
`;

export const SubButton = styled.button`
  background: ${({ theme }) => theme.colors.secondary};
  border: none;
  padding: 5px;
`;

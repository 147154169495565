import styled from "styled-components";

export const Artist = styled.p``;

export const Card = styled.div`
  &:hover {
    opacity: 0.5;
  }

  p {
    font-weight: lighter;
  }
`;

export const Date = styled.p``;

export const Details = styled.div`
  margin-top: 1rem;
`;

export const Title = styled.h2``;

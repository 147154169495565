import styled from "styled-components";
import { StyledIconBase } from "@styled-icons/styled-icon";

export const Audio = styled.audio``;

export const Button = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  border: none;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1em;
  margin-top: 2em;
  padding: 0.5em;

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
`;

export const Cards = styled.div`
  display: Grid;
  gap: 3rem 1.25rem;
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  min-width: 100%;

  @media only screen and (max-width: 1024px) {
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Play = styled.button`
  &:hover {
    opacity: 0.5;
  }

  ${StyledIconBase} {
    width: 4rem;
    height: 4rem;
  }

  @media only screen and (max-width: 767px) {
    ${StyledIconBase} {
      color: black;
    }
  }
`;

export const Source = styled.source``;

export const Title = styled.h1``;

export const Toggle = styled.div`
  display: flex;
  justify-content: center;
  scroll-margin: 2.5rem;

  @media only screen and (max-width: 767px) {
    justify-content: flex-start;
  }
`;

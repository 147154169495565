import React from "react";
import Card from "../components/Card/Card";
import { ContentQuery } from "../../graphql-types";

interface Props {
  arr: ContentQuery["allContentfulWebsiteContent"]["nodes"];
  node: ContentQuery["allContentfulWebsiteContent"]["nodes"][0];
}

const useCards = (arr: Props["arr"]): Array<React.ReactNode> => {
  const [value, setValue] = React.useState<Array<React.ReactNode>>([]);

  React.useEffect(() => {
    setValue(
      arr.map((node: Props["node"]) => <Card data={node} key={node.title} />)
    );
  }, []);
  return value;
};

export default useCards;

import * as React from "react";
import type { HeadFC } from "gatsby";
import { graphql } from "gatsby";
import { ThemeProvider } from "styled-components";
import theme from "../styles/theme";
import GlobalStyles from "../styles/GlobalStyles";
import { ContentQuery } from "../../graphql-types";
import Header from "../components/Header/Header";
import Projects from "../components/Projects/Projects";
import Radio from "../components/Radio/Radio";
import Info from "../components/Info/Info";
import Subscribe from "../components/Subscribe/Subscribe";
import HomeButton from "../components/HomeButton/HomeButton";
import SEO from "../components/SEO/SEO";
import { ScrollContext } from "../contexts/ScrollContext";

interface Props {
  data: ContentQuery;
  el: ContentQuery["allContentfulWebsiteContent"]["nodes"][0];
}

const IndexPage: React.FC<Props> = ({ data }) => {
  const content = data.allContentfulWebsiteContent.nodes;
  const radioShows = content.filter((el) => el.type === "radio");
  const projects = content.filter((el) => el.type === "project");
  const { scrollY } = React.useContext(ScrollContext);

  React.useEffect(() => {
    if (scrollY) {
      window.scrollTo(0, scrollY);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <HomeButton />
      <Header />
      {projects ? <Projects projects={projects} /> : null}
      {radioShows ? <Radio radioShows={radioShows} /> : null}
      <Info />
      <Subscribe />
    </ThemeProvider>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <SEO />;

export const query = graphql`
  query Content {
    allContentfulWebsiteContent(sort: { order: DESC, fields: date }) {
      nodes {
        title
        artist
        date
        type
        image {
          gatsbyImageData(width: 1200, height: 900)
        }
      }
    }
  }
`;

import React from "react";
import * as S from "./SectionHeader.styles";

interface Props {
  children: React.ReactNode;
}

const SectionHeader: React.FC<Props> = ({ children }) => {
  return <S.SectionHeader>{children}</S.SectionHeader>;
};

export default SectionHeader;

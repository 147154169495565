import styled from "styled-components";

export const Header = styled.header`
  align-items: center;
  background: ${({ theme }) => theme.colors.secondary};
  display: flex;
  min-height: 100vh;
  padding: 5vh 5vw;
  scroll-snap-align: start;
`;

export const Link = styled.a``;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  font-size: 2em;
  justify-content: center;

  a:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

export const Title = styled.h1`
  align-items: center;
  display: flex;
  font-size: 10vw;

  @media only screen and (max-width: 767px) {
    font-size: 22vw;
  }
`;

import React from "react";
import { Link } from "gatsby";
import * as S from "./Header.styles";
import Grid from "../Grid/Grid";

const Header = () => {
  return (
    <S.Header>
      <Grid>
        <S.Title>Ormside</S.Title>
        <S.Nav>
          <Link to="#projects">Projects</Link>
          <Link to="#radio">Radio</Link>
          <Link to="#info">Info</Link>
          <S.Link target="_blank" href="https://linktr.ee/ormside">
            Events
          </S.Link>
        </S.Nav>
      </Grid>
    </S.Header>
  );
};

export default Header;

import React from "react";
import { PlayArrow } from "@styled-icons/material/PlayArrow";
import { Pause } from "@styled-icons/material/Pause";
import * as S from "./Radio.styles";
import theme from "../../styles/theme";
import Section from "../Section/Section";
import SectionHeader from "../SectionHeader/SectionHeader";
import { ContentQuery } from "../../../graphql-types";
import { RadioContext } from "../../contexts/RadioContext";
import useCards from "../../hooks/useCards";

interface Props {
  radioShows: ContentQuery["allContentfulWebsiteContent"]["nodes"];
}

const Radio = ({ radioShows }: Props) => {
  const { num, text, toggle, setTotal } = React.useContext(RadioContext);
  const initNum = React.useRef<number>(num);
  const [playing, isPlaying] = React.useState(false);
  const didMountRef = React.useRef<boolean>(false);

  const audio = React.useRef<HTMLAudioElement>(null);
  const el = React.useRef<HTMLDivElement>(null);
  const cards = useCards(radioShows);

  React.useEffect(() => {
    setTotal(radioShows.length);
  }, []);

  React.useEffect(() => {
    if (didMountRef.current) {
      el?.current &&
        el.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    if (num !== initNum.current) {
      didMountRef.current = true;
    }
  }, [num]);

  React.useEffect(() => {
    if (playing) {
      audio?.current && audio.current.play();
    } else {
      audio?.current && audio.current.pause();
    }
  }, [playing]);

  return (
    <Section id="radio" bgColor={theme.colors.radio.main}>
      <SectionHeader>
        <S.Title>Radio</S.Title>
        {/* <S.Play onClick={() => isPlaying(!playing)}>
          {!playing ? <PlayArrow size={70} /> : <Pause size={70} />}
          <audio ref={audio} id="listen">
            <source
              src="https://listen.ormside.co.uk/radio/8000/radio.mp3"
              type="audio/mpeg"
            />
          </audio>
        </S.Play> */}
      </SectionHeader>
      <S.Grid>
        <S.Cards>{cards.slice(0, num)}</S.Cards>
        <S.Toggle ref={el}>
          <S.Button onClick={toggle}>{text}</S.Button>
        </S.Toggle>
      </S.Grid>
    </Section>
  );
};

export default Radio;

import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Instagram } from "@styled-icons/boxicons-logos/Instagram";
import { Twitter } from "@styled-icons/boxicons-logos/Twitter";
import { Youtube } from "@styled-icons/boxicons-logos/Youtube";
import { Facebook } from "@styled-icons/boxicons-logos/Facebook";
import { Linktree } from "@styled-icons/simple-icons";
import * as S from "./Info.styles";
import theme from "../../styles/theme";
import Section from "../Section/Section";
import SectionHeader from "../SectionHeader/SectionHeader";
import Grid from "../Grid/Grid";

const Info = () => {
  const [largeLogo, setLargeLogo] = React.useState(false);

  React.useEffect(() => {
    if (window.innerWidth > 2500) {
      setLargeLogo(true);
    }
  }, []);

  return (
    <Section id="info" bgColor={theme.colors.info.main}>
      <SectionHeader>
        <S.Title>Info</S.Title>
      </SectionHeader>
      <Grid>
        <S.Details>
          <S.Address>
            <S.Link
              target="_blank"
              href="https://goo.gl/maps/HQY3WzGEC9EyypHu9"
            >
              ORMSIDE PROJECTS <br />
              Unit 32 Ormside St <br />
              Penarth Centre <br />
              SE15 1TR
            </S.Link>
          </S.Address>
          <S.Email>
            <S.Link href="mailto:contact@ormside.co.uk">
              contact@ormside.co.uk
            </S.Link>
          </S.Email>
          <S.Description>
            <S.Bold>Ormside Projects &gt; </S.Bold>
            arts & music project space, est 2015.
          </S.Description>
          <S.Commercial>
            <S.Bold>Commercial &gt;</S.Bold> The space is also designed for
            photo & video productions. Please get in touch for more info
            at&nbsp;
            <S.Link href={"mailto:shoot@ormside.co.uk"}>
              shoot@ormside.co.uk
            </S.Link>
          </S.Commercial>
          <S.Affiliates>
            <S.Bold>People / Links / Past Projects </S.Bold> &gt;&nbsp;
            <S.Link
              href="https://twitter.com/JessicaBoyall1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jess&nbsp;Boyall
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://lolina.bandcamp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Lolina
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://5gatetemple.bandcamp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              5&nbsp;Gate&nbsp;Temple
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.instagram.com/f_hyperlocal/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              Hyperlocal
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.tate.org.uk/whats-on/tate-modern/performance/nkisi-spiral"
              target="_blank"
              rel="noopener noreferrer"
            >
              Charlie&nbsp;Hope
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://ra.co/podcast/660"
              target="_blank"
              rel="noopener noreferrer"
            >
              NKISI
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.aqnb.com/2020/07/21/curtly-thomas/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Curtly&nbsp;Thomas
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.facebook.com/clubcouturexxx-537368339719735"
              target="_blank"
              rel="noopener noreferrer"
            >
              clubcouture
            </S.Link>
            , Anita, Freya,&nbsp;
            <S.Link
              href="https://www.youtube.com/watch?v=EuU1LNGSro4"
              target="_blank"
              rel="noopener noreferrer"
            >
              BALA CLUB
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.facebook.com/peopledrift/"
              target="_blank"
              rel="noopener noreferrer"
            >
              People&nbsp;Drift
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://soundcloud.com/jim-janco"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jim&nbsp;Janco
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://soundcloud.com/severineyveline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sev
            </S.Link>
            , Sound&nbsp;Check&nbsp;USB, Casper,&nbsp;
            <S.Link
              href="https://soundcloud.com/ajpain"
              target="_blank"
              rel="noopener noreferrer"
            >
              AJ&nbsp;Pain
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.susularoche.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Susu&nbsp;Laroche
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://soundcloud.com/oxhy"
              target="_blank"
              rel="noopener noreferrer"
            >
              OHXY
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.facebook.com/venueM.O.T/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Venue&nbsp;MOT
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://diyspaceforlondon.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              DIY&nbsp;Space&nbsp;For&nbsp;London
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.facebook.com/amgineacoustic"
              target="_blank"
              rel="noopener noreferrer"
            >
              Amgine&nbsp;Acoustic
            </S.Link>
            , Fara&nbsp;Fara,&nbsp;
            <S.Link
              href="https://soundcloud.com/covco"
              target="_blank"
              rel="noopener noreferrer"
            >
              Covco
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://soundcloud.com/suutoo"
              target="_blank"
              rel="noopener noreferrer"
            >
              Suutoo
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.nts.live/shows/relax-w-reni"
              target="_blank"
              rel="noopener noreferrer"
            >
              RE:LAX
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://soundcloud.com/shannensp"
              target="_blank"
              rel="noopener noreferrer"
            >
              Shannon&nbsp;SP
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://soundcloud.com/mellowdramatics94"
              target="_blank"
              rel="noopener noreferrer"
            >
              mellowdramatics
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://crisisfarm.blogspot.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              CRISIS&nbsp;FARM
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.nts.live/shows/mobbs"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mobbs
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://twitter.com/jonrust?lang=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              John&nbsp;Rust
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://ra.co/events/1121686"
              target="_blank"
              rel="noopener noreferrer"
            >
              Joy&nbsp;O
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://lordtusk.bandcamp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Lord&nbsp;Tusk
            </S.Link>
            , NRG,&nbsp;
            <S.Link
              href="https://aircode.bandcamp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              aircode
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.nts.live/shows/lawsonbenn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Public Order
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://soundcloud.com/jlte"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jlte
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://jupiterwoods.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jupiter&nbsp;Woods
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.peak-art.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Peak&nbsp;London
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://soundcloud.com/acolytesloc"
              target="_blank"
              rel="noopener noreferrer"
            >
              Acolytes
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.mixcloud.com/acolderconsciousness/stream/"
              target="_blank"
              rel="noopener noreferrer"
            >
              A&nbsp;Colder&nbsp;Consciousness
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://newnoveta.blogspot.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              New&nbsp;Noveta
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://paulpurgas.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Paul&nbsp;Purgas
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.aqnb.com/2016/07/13/opening-party-fuck-what-they-think-penarth-centre-jul-15/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @Gaybar
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.nts.live/shows/body-motion"
              target="_blank"
              rel="noopener noreferrer"
            >
              Body&nbsp;Motion
            </S.Link>
            , Soaking,&nbsp;
            <S.Link
              href="https://www.haunterrecords.com/intro"
              target="_blank"
              rel="noopener noreferrer"
            >
              Haunter&nbsp;Records
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.instagram.com/blackobsidian_soundsystem/"
              target="_blank"
              rel="noopener noreferrer"
            >
              B.O.S.S
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.nts.live/"
              target="_blank"
              rel="noopener noreferrer"
            >
              NTS
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.instagram.com/noxiouscrush/"
              target="_blank"
              rel="noopener noreferrer"
            >
              noxiouscrush
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.instagram.com/awwwwaoffxe/"
              target="_blank"
              rel="noopener noreferrer"
            >
              awwwwa
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://www.mixcloud.com/sunflowerfmfm/20180821-2224-kline/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Klein
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://xenogothic.com/2019/01/11/for-k-punk-2019/"
              target="_blank"
              rel="noopener noreferrer"
            >
              For&nbsp;K-Punk
            </S.Link>
            , Unit&nbsp;21, Winner, Cool&nbsp;Ice&nbsp;Man,&nbsp;
            <S.Link
              href="https://www.mixcloud.com/sunflowerfmfm/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sunflower&nbsp;FM
            </S.Link>
            ,&nbsp;
            <S.Link
              href="https://musicvenuetrust.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Music&nbsp;Venue&nbsp;Trust
            </S.Link>
          </S.Affiliates>
          <S.Socials>
            <S.SocialLink
              target="_blank"
              href="https://www.instagram.com/ormsideprojects"
            >
              <Instagram size={48} />
            </S.SocialLink>
            <S.SocialLink
              target="_blank"
              href="https://twitter.com/OrmsideProjects"
            >
              <Twitter size={48} />
            </S.SocialLink>
            <S.SocialLink
              target="_blank"
              href="https://www.youtube.com/channel/UCFV6R74iy8gJfxnpNFPxetg"
            >
              <Youtube size={48} />
            </S.SocialLink>
            <S.SocialLink
              target="_blank"
              href="https://www.facebook.com/ormsideprojects/"
            >
              <Facebook size={48} />
            </S.SocialLink>
            <S.SocialLink target="_blank" href="https://linktr.ee/ormside">
              <Linktree size={48} />
            </S.SocialLink>
          </S.Socials>
          {largeLogo ? (
            <StaticImage
              width={300}
              src="../../images/ace-logo.png"
              alt="ACE logo"
            />
          ) : (
            <StaticImage
              width={150}
              src="../../images/ace-logo.png"
              alt="ACE logo"
            />
          )}
        </S.Details>
      </Grid>
    </Section>
  );
};

export default Info;

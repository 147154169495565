import styled from "styled-components";
import { StyledIconBase } from "@styled-icons/styled-icon";

export const Address = styled.h2`
  font-size: 2rem;
`;

export const Affiliates = styled.p`
  a {
    font-weight: lighter;
    width: -moz-fit-content;
    width: fit-content;
  }
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const Commercial = styled.p``;

export const Description = styled.p``;

export const Details = styled.div`
  > * {
    margin-bottom: 1rem;
  }
`;

export const Email = styled.h2``;

export const Link = styled.a`
  display: inline-block;

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.secondary};
    cursor: pointer;
  }
`;

export const LinkTree = styled.h2``;

export const Socials = styled.div`
  a:not(:first-child) {
    margin-left: 1.5rem;
  }

  @media only screen and (max-width: 446px) {
    display: flex;
    justify-content: space-between;
    width: 85vw;

    a:not(:first-child) {
      margin-left: 0px;
    }
  }

  @media only screen and (min-width: 2500px) {
    ${StyledIconBase} {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const SocialLink = styled.a`
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

export const Title = styled.h1``;

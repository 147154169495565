import styled from "styled-components";

export const Section = styled.section`
  background: ${(props) => props.color};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 5vh 5vw;
  scroll-snap-align: start;

  > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

import React from "react";
import * as S from "./Project.styles";
import theme from "../../styles/theme";
import Section from "../Section/Section";
import SectionHeader from "../SectionHeader/SectionHeader";
import { ContentQuery } from "../../../graphql-types";
import { ProjectsContext } from "../../contexts/ProjectsContext";
import useCards from "../../hooks/useCards";

interface Props {
  projects: ContentQuery["allContentfulWebsiteContent"]["nodes"];
}

const Projects = ({ projects }: Props) => {
  const { num, text, toggle, setTotal } = React.useContext(ProjectsContext);
  const initNum = React.useRef<number>(num);
  const didMountRef = React.useRef<boolean>(false);
  const el = React.useRef<HTMLDivElement>(null);
  const cards = useCards(projects);

  React.useEffect(() => {
    setTotal(projects.length);
  }, []);

  React.useEffect(() => {
    if (didMountRef.current) {
      el?.current &&
        el.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    if (num !== initNum.current) {
      didMountRef.current = true;
    }
  }, [num]);

  return (
    <Section id="projects" bgColor={theme.colors.projects.main}>
      <SectionHeader>
        <S.Title>Projects</S.Title>
      </SectionHeader>
      <S.Grid>
        <S.Cards>{cards.slice(0, num)}</S.Cards>
        <S.Toggle ref={el}>
          <S.Button onClick={toggle}>{text}</S.Button>
        </S.Toggle>
      </S.Grid>
    </Section>
  );
};

export default Projects;
